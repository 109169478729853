// @ts-check
/** @typedef {import("./utils/types").Options} Options  */
/** @typedef {import("./utils/types").Globals} Globals  */
/** @typedef {import("./utils/types").GptAdElement} GptAdElement  */

// Utils
import { log } from "./utils/log";
import { performance } from "./utils/perf";
import { loadScript } from "./utils/load";
import { allowTracking, setGDPRConsented } from "./consent";

import { initQueue, gptQueue, justInTimeQueue, resetQueues } from "./queues";

// Core functionality
import { render } from "./render";

// Plugins
import mixins from "./mixins";
import vendors from "./vendors";

import { GptAd, registerElements } from "./models";
import { setupBreakpoints } from "./breakpoints";
import { createOutOfPageUnit } from "./outofpage";

import { debugDisableAds, gptUrl } from "./constants";

import { handleCMPConsent, isGDPRCmp } from "./consent";

import { getOptions, getGlobals, setOptions, clearOptions, setGlobals } from "./options";
import { requireGeoData } from "./utils/geo";
import { resetRandomNumberKeyValues } from "./experiments/constants";
import { resetRefreshCount } from "./mixins/refresh";
import { resetPrebid } from "./vendors/prebid";
import { resetCarbon } from "./vendors/carbon";
import { dispatchHasAdblock } from "./utils/adblock";

/**
 *  Add variables to the globals object for pageviews
 */
function addPageviewTargeting() {
  const dataLayerPageObj = window.dataLayer?.find((dl) => dl.event === "pageview")?.page;
  if (!dataLayerPageObj) return;

  setGlobals({
    cat: dataLayerPageObj?.article?.categories.map((cat) => cat.slug).join(","),
    channel: dataLayerPageObj?.article?.channels?.map((channel) => channel.slug).join(","),
    report: dataLayerPageObj?.article?.editorialProject?.slug
  });
}

/**
 * Entrypoint for ad loading controller
 *
 * @class      Controller (name)
 * @param      {Options}  options  The ads.js
 */
export default function Controller(options) {
  if (debugDisableAds) {
    console.log("Hummingbird is disabled");
    dispatchHasAdblock();
    return;
  }

  const self = this;

  log("Created ads controller", this);

  // Always clone the object, just in case JSPM
  // or a functional purity cult has frozen it.
  log("Ad options set to", options);
  setOptions(options);

  // add targeting early
  addPageviewTargeting();

  // In a CMP is present, handle consent
  // and wait for geodata in parallel
  if (isGDPRCmp()) {
    const promise = Promise.all([handleCMPConsent(), requireGeoData()])
      .then((results) => {
        // results is an array of what each promise passes to resolve.
        // since consent is first, we'll pull it's state (ok?) off the
        // first element in the array.
        const { ok } = results[0];
        log(`🌍 GDPR consent ${ok ? "was" : "was not"} granted!`);
        self.init();
      })
      .catch((err) => {
        setGDPRConsented(false);
        log(`🌍 Could not get GDPR consent`, err);
        self.init();
      });
  } else if (!allowTracking()) {
    // Continue without collecting geodata
    self.init();
  } else {
    // Otherwise just wait for geodata
    requireGeoData().then(this.init.bind(this));
  }
}

/**
 * TODO: Linter thinks this is node, not a browser. eslint settings?
 * @type NodeJS.Timer
 */
Controller.prototype.loop;

/**
 * @param {boolean} doPoll should the code poll the DOM for new gpt-ads?
 */
Controller.prototype.init = function (doPoll = true) {
  let self = this;

  log(`Init ad Controller with automatic polling ${doPoll ? "enabled" : "disabled"}.`);
  performance.mark("ads:controller_loaded");

  // Setup globals, adtest, peer39, etc.
  for (let mixin in mixins) {
    mixins[mixin].call(this);
  }

  // Load this right away
  loadScript(gptUrl, "GPT", dispatchHasAdblock);

  // Invoke with the controller
  let exec = function (fn) {
    fn.call(self);
  };
  initQueue.execute(exec);
  initQueue.setCallback(exec);

  if (doPoll) {
    registerElements();
  }

  // Setup Promises for Third Party data sources
  performance.mark("ads:vendors_start");
  let requirements = Object.keys(vendors).map((key) => {
    performance.mark(`ads:start:${key}`);
    log(`Ad vendor started: ${key}`);
    const vendor = vendors[key];
    const promise = new Promise(vendor.bind(self));

    // Measure how long each one takes to complete.
    promise.then(function (result) {
      log(`${key} resolved: `, result);
      log(`Ad vendor ready: ${key}`);
      performance.mark(`ads:complete:${key}`);
      performance.measure(
        `ads:measure:${key}`,
        `ads:start:${key}`,
        `ads:complete:${key}`
      );
    });
    return promise;
  });

  Promise.all(requirements).then(function () {
    log("Vendors loaded. Pushing to GPT cmd queue. 💨");
    performance.mark("ads:vendors_ready");
    performance.measure("ads:measure:vendors", "ads:vendors_start", "ads:vendors_ready");

    gptQueue.push(function () {
      //
      // Begin running GPT
      // We need to wait for all page level targeting to be
      // ready _before_ doing this. GPT's developers
      // assume you call things in the correct order,
      // and warn that violating that order may cause
      // race conditions.
      //
      // @see: http://bit.ly/2gr1gfy
      //

      self.startGpt();

      // Just this queue, and then make it
      // run anything it receives immediately
      justInTimeQueue.execute(exec);
      justInTimeQueue.setCallback(exec);

      // Start listening for new ads to render
      log("Started making ad calls 🚀");
      performance.mark("ads:run_first_render");
      render();

      // Run again every quarter second
      // to catch ads that should lazy load.
      self.loop = setInterval(function () {
        // Don't do anything while we're out of view
        if (document.visibilityState === "hidden") {
          return;
        }

        // Are there any new ads?
        if (doPoll) {
          registerElements();
          GptAd.all().map((ad) => ad.createGptSlot());
        }

        render();
      }, 250);
    });
  });
};

//
// Set page-level targeting from key/values of the globals object.
//
// To avoid race conditions within the GPT internals, this should
// only be called inside `start`, where we can carefully control
// the order of events. @see: http://bit.ly/2gr1gfy
//
Controller.prototype.setPageTargeting = function () {
  let self = this;
  let key, value;

  let globals = getGlobals();

  for (key in globals) {
    // setTargeting only accepts strings and Arrays of strings.
    value = globals[key];

    // Skip empty values
    if (value === undefined) {
      continue;
    }

    if (Array.isArray(value)) {
      value = value.map((a) => a.toString());
    } else if (value !== null) {
      value = value.toString();
    }
    googletag.pubads().setTargeting(key, value);
    log("Set global targeting: " + key + "=" + value);
  }
};

//
// Register ads and start service.
//
Controller.prototype.startGpt = function () {
  // check for adblock
  try {
    googletag.pubads().setTargeting("gptIsWorking", "true");
    if (!googletag.pubads().getTargeting("gptIsWorking")) {
      dispatchHasAdblock();
    }
  } catch (e) {
    dispatchHasAdblock();
  }

  let self = this;
  self.setPageTargeting();

  // docs: https://developers.google.com/publisher-tag/reference#googletag.PrivacySettingsConfig
  googletag.pubads().setPrivacySettings({
    restrictDataProcessing: !allowTracking(),
    // @ts-ignore - this is a valid privacy setting
    childDirectedTreatment: false,
    // @ts-ignore - this is a valid privacy setting
    // https://support.google.com/admanager/answer/7678538
    nonPersonalizedAds: !allowTracking(),
  });
  // This seems to be redundant (and also not in the api docs), but just to be safe
  // docs: https://support.google.com/adsense/answer/7670312
  if (!allowTracking() && googletag.pubads().setRequestNonPersonalizedAds) {
    googletag.pubads().setRequestNonPersonalizedAds(1);
  }

  // Set up all the services and disable initial
  // load before we start registered ads.
  googletag.pubads().enableSingleRequest();
  googletag.pubads().disableInitialLoad();
  googletag.pubads().setSafeFrameConfig({ sandbox: true });
  googletag.pubads().enableAsyncRendering();
  googletag.pubads().collapseEmptyDivs();
  googletag.pubads().setCentering(true);
  googletag.enableServices();
  log("Started GPT services");

  // Turn all the gpt-ad elements into GPT slots
  GptAd.all().map((ad) => ad.createGptSlot());

  // Create the outofpage unit if it's enabled.
  createOutOfPageUnit();

  setupBreakpoints();
};

Controller.prototype.pause = function () {
  // Stop bids and rendering
  if (this.loop) {
    log("Pausing ad loadings lifecycle, anticipating a new pageview");
    clearInterval(this.loop);
  }
};

/**
 * For new pageviews via Ajax, clear all the slots and targeting, and reset <gpt-ad>'s to their
 * state to prepare for a fresh ad call.
 *
 * Call `controller.start` to load ads when the DOM
 *  is updated.
 *
 * Assumes old <gpt-ad>s are no longer in the DOM and the url has already changed
 *
 * @TODO:  It needs unit tests.
 *
 * @param {Options} options
 */
Controller.prototype.reset = function (options) {
  // clear out old values
  clearOptions();

  // Stop running the renderer while we reset
  if (this.loop) {
    clearInterval(this.loop);
  }

  // remove all the ads and re-register ads that are in the DOM
  GptAd.clear();

  // DESTROY ALL THE THINGS!
  googletag.destroySlots();
  googletag.pubads().clearTargeting();
  googletag.pubads().clear();
  resetPrebid();
  resetCarbon();

  log("Ad options changed to", options);

  resetRefreshCount();
  resetQueues();
  resetRandomNumberKeyValues();
  setOptions(options);
  this.init();
};

/**
 * For the bookmarklet, which can only see the controller object
 */
Controller.prototype.getOptions = function () {
  return getOptions();
};
