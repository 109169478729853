// @ts-check
/** @typedef {import("./utils/types").GptAdElement} GptAdElement  */
/** @typedef {import("./models").GptAd} GptAd  */

/**
 * Stores a history of recent bids in localStorage. We pass the previous bids
 * to the performance pixel for analysis.
 */

import { log, logError } from "./utils/log";
import { saveUnifiedYield } from "./unifiedYield";
import { allowTracking } from "./consent";

// the number of entries to store
const LOG_SIZE = 50;

const bidStoreKey = "bidStore3";

/**
 * Remove any old keys
 */
function cleanLocalStorage() {
  try {
    window.localStorage.removeItem("bidStore");
    window.localStorage.removeItem("bidStore2");
  } catch (e) {
    logError(`Couldn't remove old bidStore key: `, e);
  }
}

// Retrieve the store from localstorage
// This only runs once, the first time this file is imported
let bidStore = [];
try {
  if (allowTracking()) {
    let bidStoreString = localStorage.getItem(bidStoreKey);
    bidStore = bidStoreString ? JSON.parse(bidStoreString) || [] : [];
  }
} catch (e) {
  log(`Couldn't parse bidStore: `, bidStore);
}

// on window unload, save the store for next time
window.addEventListener("unload", saveStore);

/**
 * @param {GptAd} gptAd
 */
function recordBids(gptAd) {
  if (gptAd.tasks.length === 0) {
    // This ad was not eligible for bids
    return;
  }

  let cpm = gptAd.getMaxBid() || 0;
  log(`Remembering bid for ${gptAd.id}: ${cpm}`);
  bidStore.unshift(cpm);
  bidStore.length = bidStore.length < LOG_SIZE ? bidStore.length : LOG_SIZE;
  requestAnimationFrame(() => saveUnifiedYield(getAverageBid()));
}

/**
 * Save the array to localstorage
 */
function saveStore() {
  try {
    // Do not store anything in localstorage if we've turned off tracking
    if (!allowTracking()) {
      return;
    }
    localStorage.setItem(bidStoreKey, JSON.stringify(bidStore));
    cleanLocalStorage();
  } catch (e) {
    console.warn(e);
    console.warn("Local storage error. Bid history not saved.");
  }
}

/**
 * @return {String} a comma delimited list of bid cpms
 */
function getBids() {
  return bidStore.join(",");
}

function getAverageBid() {
  // Shortcut, don't think about divide-by-zero errors
  if (bidStore.length === 0) {
    return 0;
  }

  let result =
    // Bids are stored as strings. To do math we need to parse them.
    bidStore
      .map((bid) => parseFloat(bid))
      // get the average
      .reduce((a, b) => a + b) / bidStore.length;

  return result;
}

export default {
  getLog: () => bidStore,
  recordBids,
  getBids,
  getAverageBid: getAverageBid,
};
