// @ts-check
import { getHattieConfig } from "./vendors/hattie";
import { allowTracking } from "./consent";

const unifiedYieldKey = "adsUnifiedYieldValue";

/**
 * Determine if the average bid is greater
 * than the current 90th percentile maxbid
 * @returns {Boolean}
 */
function isHighValueProgrammatic(avgBid) {
  const marketData = getHattieConfig().marketData;
  let maxbid90;
  if (marketData && Object.keys(marketData).length) {
    maxbid90 = marketData.maxbid90;
  }
  return !!maxbid90 && avgBid >= maxbid90;
}

function createUnifiedYield(avgBid) {
  return {
    // Average of the last 20 bids
    avgBid: avgBid.toFixed(2),
    // Boolean for if avgBid is higher than the 90% maxbid from Hattie
    highValueProgrammatic: isHighValueProgrammatic(avgBid),
    // If a user is in a segment with a high sell-through rate (TK)
    directSegments: null,
  };
}

/**
 * Save the object to localstorage
 */
export function saveUnifiedYield(avgBid) {
  // Do not store anything in localstorage if we've turned off tracking
  if (!allowTracking()) {
    return;
  }
  try {
    localStorage.setItem(unifiedYieldKey, JSON.stringify(createUnifiedYield(avgBid)));
  } catch (e) {
    console.warn(e);
    console.warn("Local storage error. Unified yield object not saved.");
  }
}
