// @ts-check
import { log } from "./utils/log";
import { cookies } from "./utils/cookies";
import { debug, urlParams } from "./constants";
import { ensureCMPQueueExists } from "./utils/stub-cmp";

// Ids for basic tracking and showing ads
// https://iabeurope.eu/iab-europe-transparency-consent-framework-policies/#_Appendix_A_Definitions_of_Purposes_Features_and_Categories_of_data
const purposeConsentsRequired = [1, 2, 3, 4, 7, 9];

// And some vendors are required
const vendorConsentsRequired = [];

let gdprConsented = false;

/**
 * Look at the consent object and confirm whether all
 * the require id's are approved.
 *
 * @param      {Array}    idsRequired  List of consent id's required
 * @param      {Object}   consentMap   The consent map from the CMP in {id: bool} format
 * @return     {Boolean}  Do you have all the consent you need?
 */
export function checkCMPPermissions(consentMap, idsRequired) {
  return (
    idsRequired.map((id) => consentMap[id] || false).filter((value) => value !== true)
      .length === 0
  );
}

/**
 * Read CMP data, determine if it's populated and if we have
 * the consents required to show ads.
 *
 * @return {Promise}  Resolves with {hasData: <Boolean>, consented: <Boolean>}
 */
function checkCMP() {
  return new Promise((resolve, reject) => {
    window.__tcfapi("getTCData", 2, function (data, isSuccess) {
      log("CMP: getTCData returned", data, isSuccess);

      if (data.eventStatus === "cmpuishown") {
        log("CMP: No data available because CMP UI is showing");
        return resolve({ hasData: false, consented: null });
      }
      log("CMP: Data is available. data.purpose.consents = ", data.purpose.consents);

      const { purpose, vendor } = data;

      const hasPurposeConsent = checkCMPPermissions(
        purpose.consents,
        purposeConsentsRequired
      );

      const hasVendorConsent = checkCMPPermissions(
        vendor.consents,
        vendorConsentsRequired
      );

      gdprConsented = hasPurposeConsent && hasVendorConsent;

      resolve({ hasData: true, consented: hasPurposeConsent && hasVendorConsent });
    });
  });
}

/**
 * Gets consent information from the CMP.
 *
 * @return     {Promise}  Resolves with {consentGranted: <Boolean>}
 */
function requireCMPConsent() {
  // The "we know for a fact that we can resolve consent" promise
  return new Promise((resolve, reject) => {
    if (!isGDPRCmp()) {
      log("GDPR CMP not required.");
      resolve({ consentGranted: true });
    }
    // First attempt promise
    checkCMP().then(({ hasData, consented }) => {
      // Our initial check works because there's data in the cookie
      if (hasData) {
        log("CMP: requireConsent resolved with consent", consented);
        resolve({ consentGranted: consented });
      } else {
        // Need to wait for the user to click something in the CMP
        window.__tcfapi("addEventListener", 2, (data, isSuccess) => {
          log("CMP: addEventListener got event", data, isSuccess);
          log(
            "CMP: requireConsent will resolve when eventStatus = useractioncomplete. eventStatus =",
            data.eventStatus
          );
          if (data.eventStatus === "useractioncomplete") {
            checkCMP().then(({ hasData, consented }) => {
              resolve({ hasData, consentGranted: consented });
              window.__tcfapi("removeEventListener", 2, () => {
                log("CMP: my work here is done.");
              });
            });
          }
        });
      }
    });
  });
}

/**
 * Handles all consent needs with the GDPRCMP
 *
 * @return     {Promise}  Resolves with {ok: <Boolean>}
 */
export function handleCMPConsent() {
  return new Promise((resolve) => {
    // Do we need consent from the GDPR CMP?
    if (isGDPRCmp()) {
      // We don't know how this will be loaded, but if the cookie
      // is set, assume it will be and stub it out.
      ensureCMPQueueExists();

      // Do we have consent?
      requireCMPConsent().then(({ consentGranted }) => {
        log("GDPR CMP resolved with consent", consentGranted);
        resolve({ ok: consentGranted });
      });
    } else {
      log("GDPR CMP not required.");
      resolve({ ok: true });
    }
  });
}

/**
 * Did the user opt out under CCPA or similiar?
 *
 * @return {Boolean} - Did user opt out?
 */
export function isDoNotSell() {
  const dns_cookie = "atl_ccpa";
  const edu_cookie = "edu_do_not_sell";
  const isAppCCPA = urlParams["app-privacy"] === "ccpa";
  return (
    isAppCCPA ||
    cookies.get(dns_cookie) !== null ||
    cookies.get(edu_cookie) !== null ||
    debug.donotsell ||
    false
  );
}
export function setGDPRConsented(value) {
  if (isGDPRCmp()) {
    gdprConsented = value;
  }
}

/**
 * Is the user in Europe, according to the CDN, and needs to use the CMP
 * (consent management platform) popup.
 *
 * Some situations where a user still can provide consent but NOT
 * through the CMP popup (so this will return false):
 *  - The user is in California and isn't do-not-sell (uses CCPA settings)
 *  - App user and under GDPR (uses native app settings)
 *  - App user not under GDPR, and doesn't have app-privacy=apple-att (uses Apple consent settings)
 *
 * @return {Boolean}
 */
export function isGDPRCmp() {
  // /webview/ does not use the CMP, so we don't treat it as though there is a CMP.
  // We don't have to check the CDN, so this check doesn't apply to that.
  if (isApp()) {
    return false;
  }
  const gdpr_cookie = "is_gdpr";
  return (
    cookies.get(gdpr_cookie) === "1" ||
    debug.gdpr ||
    window.location.search.match(/is_gdpr=1/) ||
    false
  );
}

/**
 * Apple ATT consent to track rejected in app.
 *
 * @return {Boolean}
 */
export function isAppleATT() {
  return urlParams["app-privacy"] === "apple-att";
}

export function isApp() {
  return window.location.pathname.includes("/webview/");
}

/**
 * We can still ship non-personalized ads if this is true.
 * @returns {boolean} if we have to turn off 1st and 3rd party tracking
 */
export function allowTracking() {
  // this is true if we're under GDPR and consented or we're not under GDPR
  let consentedGDPR = !(isGDPRCmp() && !gdprConsented);

  // check all the types of consent we need
  return (
    !isAppleATT() &&
    !isDoNotSell() &&
    urlParams["app-privacy"] !== "gdpr" &&
    consentedGDPR
  );
}