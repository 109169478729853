// @ts-check
/** @typedef {import("../models").GptAd} GptAd  */
import { log } from "../utils/log";
import { Task } from "../utils/task";

import { lazyLoadQueue, justInTimeQueue } from "../queues";
import { getDeviceType } from "../utils/device";
import { floor, mutedAnyBidders, debug } from "../constants";
import { performance } from "../utils/perf";
import { getLoadableAds, inLazyRange } from "../lazyload";
import { render } from "../render";
import { getOptions } from "../options";
import { allowTracking } from "../consent";
import { formats } from "../formats";

const acceptedSizes = ["728x90", "970x250", "300x600", "300x250", "320x50"];

let amazonBatch = 0;



/**
 * Hard limit to wait for bids, in milliseconds
 * @type {number}
 */
const TIMEOUT = 1500;

// for items in batches 2+. Batch 1 is the first ads to load on the page.
const TIMEOUT_LATER_BATCH = { mobile: 3000, desktop: 2250 };

/**
 * Keys to log performance metrics
 * @type {Object}
 */
const PERF_KEYS = {
  initload: "ads:amazon:init",
  endload: "ads:amazon:ready",
  initbid: "ads:amazon:bid:start",
  endbid: "ads:amazon:bid:end",
};

/**
 * Number of screens away to start lazy bidding
 * @type {number}
 */
let LAZY_BID_OFFSET = 0.5;
// for items in batches 2+. Batch 1 is the first ads to load on the page.
let LAZY_BID_OFFSET_LATER_BATCH = 1;

/**
 * Keeps track if this the first time we've fetched bids
 * @type {boolean}
 */
let isFirstBidFetch = true;

/**
 * Map of GptAd ids that we have already bid on
 * { [ad.id] = true }
 * @type       {Object}
 */
let unitsWereBid = {};

/**
 * Map of GptAd ids to their active sizes
 * { [ad.id] = [ '0x0', ... ] }
 * @type {Object}
 */
let gptAdSizes = {};

/**
 * Amazon's code. Creates window.apstag and puts a queue in its place.
 * Source:
 * https://ams.amazon.com/webpublisher/uam/docs/web-integration-documentation/integration-guide/javascript-guide/display.html
 */
function loadA9Library() {
  // @ts-ignore
  !(function (a9, a, p, s, t, A, g) {
    if (a[a9]) {
      return;
    }

    function q(c, r) {
      a[a9]._Q.push([c, r]);
    }
    a[a9] = {
      init: function () {
        q("i", arguments);
      },
      fetchBids: function () {
        q("f", arguments);
      },
      setDisplayBids: function () {},
      targetingKeys: function () {
        return [];
      },
      _Q: [],
    };
    // @ts-ignore
    A = p.createElement(s);
    // @ts-ignore
    A.async = !0;
    // @ts-ignore
    A.src = t;
    // @ts-ignore
    g = p.getElementsByTagName(s)[0];
    // @ts-ignore
    g.parentNode.insertBefore(A, g);
  })("apstag", window, document, "script", "//c.amazon-adsystem.com/aax2/apstag.js");
}

/**
 * Check if an ad has sizes we are bidding on
 * Modifies `gptAdSizes` to store the sizes we retrieve for later lookup
 * @param  {GptAd} ad     the ad to test
 * @return {boolean}
 */
function hasActiveSizes(ad) {
  // Get sizes data and filter for sizes we want to bid on
  const sizes = ad.getActiveSizes().filter(function (size) {
    return acceptedSizes.indexOf(size.join("x")) > -1;
  });

  // if no valid sizes, or its a top of page leaderboard, return
  if (!sizes.length || ad.data.format === formats.leaderboard) {
    return false;
  }
  // store the sizes we got in the global object
  gptAdSizes[ad.id] = sizes;

  return true;
}

/**
 * Determine if an ad is within range to be lazy loaded now
 * @param  {GptAd}   ad               the ad to test
 * @return {boolean}                  if it is in lazy range
 */
function isInLazyRange(ad) {
  // How far from the viewport (in 100vh) should the ad start loading
  let numScreens = ad.getLazySetting();

  // lazy load is disabled
  if (numScreens === 0) {
    return true;
  }

  // add offset number of screens so it loads early
  numScreens = numScreens + LAZY_BID_OFFSET;
  if (amazonBatch > 1) {
    numScreens = numScreens + LAZY_BID_OFFSET_LATER_BATCH;
  }
  return inLazyRange(ad.element, numScreens);
}

/**
 * Gets bids from Amazon for an array of ads
 * Does not use a callback or return a value. State is tracked by a Task placed
 * on each GptAd.
 * @param  {GptAd[]} gptAds          ads to bid on
 */
function doBid(gptAds) {
  amazonBatch++;

  const logList = gptAds.map((ad) => ad.id);
  log(`Amazon lazy bidding on`, logList);

  // mark performance for the first bid fetch only
  const DO_PERF = !!isFirstBidFetch;
  if (DO_PERF) {
    performance.mark(PERF_KEYS.initbid);
  }
  isFirstBidFetch = false;

  // lock the ads so they won't load before bids have returned
  let task = new Task("amazon");
  gptAds.forEach((ad) => ad.tasks.push(task));

  // Hard limit for resolution
  window.setTimeout(() => {
    if (!task.isComplete) {
      // unlock affected ads
      log(`Amazon bids timed out (${TIMEOUT}ms)`, logList);
      // Send bid timeout information to DFP
      gptAds.forEach((ad) => {
        // If this ad slot's bid did time out, send it to DFP.
        // If not, skip to the next ad slot.
        if (logList.includes(ad.data.id)) {
          // Prebid also sets this targeting, so don't override it
          let timedout = ad.getGptSlot().getTargeting("timedout") || [];
          timedout.push("amazon");
          ad.getGptSlot().setTargeting("timedout", timedout);
        }
      });
      task.done();
    }
  }, TIMEOUT);

  // map gptAds to a format that A9 is expecting
  const a9Slots = gptAds.map((ad) => {
    log(`Formatting ${ad.data.id} in Amazon bidding batch ${amazonBatch}`);
    return {
      slotID: ad.element.id,
      slotName: ad.getSlotName(), // Used for bidding and reporting
      sizes: gptAdSizes[ad.id],
      floor: floor,
    };
  });

  const adIds = gptAds.map((ad) => ad.element.id);
  const startPerfKey = `ads:amazon-lazybid-start:${adIds.join(",")}`;
  const endPerfKey = `ads:amazon-lazybid-end:${adIds.join(",")}`;
  const measurePerfKey = `ads:amazon-lazybid:${adIds.join(",")}`;

  performance.mark(startPerfKey);

  const fetchSettings = {
    slots: a9Slots,
    timeout: TIMEOUT,
  };

  // TODO: Remove this when experiment is complete
  if (amazonBatch > 1) {
    log(`Amazon bids for batch ${amazonBatch} will use longer timeouts.`);
    fetchSettings.timeout = TIMEOUT_LATER_BATCH.mobile;
    if (getDeviceType() === "desktop") {
      fetchSettings.timeout = TIMEOUT_LATER_BATCH.desktop;
    }
  }

  window.apstag.fetchBids(fetchSettings, function (bids) {
    log(`Received ${bids.length} Amazon bids`, logList);

    // Log it
    performance.mark(endPerfKey);
    performance.measure(measurePerfKey, startPerfKey, endPerfKey);

    log(`Set Amazon bids to slots`, logList);
    // set apstag targeting on googletag, then trigger the first DFP request
    // in googletag's disableInitialLoad integration
    window.apstag.setDisplayBids();

    // unlock affected ads
    task.done();

    // render ads
    justInTimeQueue.push(render);

    if (DO_PERF) {
      performance.mark(PERF_KEYS.endbid);
    }
  });
}

/**
 * @param  {function} resolve   returns a resolved Promise (Native code)
 */
export default function amazon(resolve) {
  // clear out units we have already bid on
  unitsWereBid = {};

  if (mutedAnyBidders && !debug["only-amazon"]) {
    log("Muting Amazon");
    resolve();
    return;
  }

  if (!allowTracking()) {
    log("🙈 TAM disabled per no tracking request.");
    resolve();
    return;
  }

  const { amazon: publisherID } = getOptions();

  // exit if amazon bidding is turned off
  if (!publisherID) {
    resolve();
    return;
  }

  performance.mark(PERF_KEYS.initload);

  loadA9Library();
  window.apstag.init({
    pubID: publisherID,
    adServer: "googletag",
  });

  // Set up repeating function to find ads in lazy loading range and bid on them
  lazyLoadQueue.push(() => {
    // get the ads we can load, we haven't already bid on, that have valid sizes
    // and are in lazy loading range
    let gptAds = getLoadableAds()
      .filter((ad) => !unitsWereBid[ad.id])
      .filter(hasActiveSizes)
      .filter((ad) => isInLazyRange(ad));

    // exit if nothing new to bid on
    if (!gptAds.length) {
      return;
    }

    // store ids of ads that have been bid on
    gptAds.forEach((ad) => (unitsWereBid[ad.id] = true));

    doBid(gptAds);
  });

  performance.mark(PERF_KEYS.endload);
  resolve();
}